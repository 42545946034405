import { buttonVariants } from "package:/components/elements/Button";
import { Link } from "package:/components/elements/Link";
import { MatomoOptOut } from "package:/components/modules/MatomoOptOut";
import HtmlToCustomTagReplacement from "@sv/html-to-custom-tag-replacement/src/index.js";
import { h } from "vue";
import { defineComponent } from "#imports";
import style from "./RichText.module.css";

// create replace instance
const replacer = new HtmlToCustomTagReplacement({ debug: false });

export const RichText = defineComponent(
  (props: {
    html: string | undefined;
    class?: string;
    onClick?: (e: MouseEvent) => void;
    variant?: "internal" | "external";
  }) => {
    let result = props.html || "";
    result = result.replace(/href="#"/g, 'href=""'); // workaround to get nuxt-link working with simple hashes
    result = replacer.replaceTags(
      result,
      "a.button",
      "Link",
      { href: "to" },
      { className: buttonVariants.big },
    );

    // log.info("result", result);
    // result = replacer.replaceTags(result, 'a.btn-secondary', 'base-button', { href: 'to' }, { variant: 'secondary' })
    // result = replacer.replaceTags(result, "table", "Table");
    result = result.replace("{matomo-optout-placeholder}", "<MatomoOptOut />"); // workaround to add matomo optout feature
    result = result.replace(/\n/g, "");
    result = result.replace(/\r/g, "");

    return () => {
      return (
        <div
          class={[style.richtext, props.variant || "external", props.class || ""]}
          onClick={(e: MouseEvent) => {
            const target = e.target as HTMLAnchorElement;

            if (target.nodeName.toLowerCase() === "a") {
              props.onClick?.(e);
            }
          }}
        >
          {/* {h({ components: { MatomoOptOut }, template: result })} */}
          {h({
            components: { Link, MatomoOptOut },
            template: result,
          })}
        </div>
      );
    };
  },
  { props: ["class", "html", "variant", "onClick"] },
);
