import { Checkbox } from "package:/components/elements/Checkbox.jsx";
import { Tracking } from "package:/utils";
import { defineComponent, onBeforeMount, ref } from "#imports";

export const MatomoOptOut = defineComponent(
  () => {
    const isOptOut = ref<boolean>(false);

    onBeforeMount(() => {
      isOptOut.value = document.cookie.includes("mtm_consent_removed");
    });

    return () => (
      <div id="matomo-opt-out" class="flex gap-4">
        <Checkbox
          id="checkbox-matomo-optout"
          class="my-1 w-full flex-none items-center"
          checked={!isOptOut.value}
          onChange={(val) => {
            isOptOut.value = !val;
            // update matomo
            Tracking.optOut(!val);
          }}
        >
          <div class="text-left">
            {isOptOut.value ? (
              <span>
                Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse nicht
                erfasst.
              </span>
            ) : (
              <span>
                Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse erfasst.
              </span>
            )}
          </div>
        </Checkbox>
      </div>
    );
  },
  {
    name: "MatomoOptOut",
    props: [],
  },
);
